import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  showMercadoPago(data) {
    var url = ConfigAPI.baseURL + "payments/mercado-pago/" + data.id + Session.getToken();
    return instance.get(url);
  },
  saveMercadoPago(data) {
    var params = data
    var url = ConfigAPI.baseURL + "payments/mercado-pago/" + data.id + Session.getToken();
    return instance.post(url,params);
  },
  testMercadoPago(data) {
    var params = data
    var url = ConfigAPI.baseURL + "payments/mercado-pago/" + data.id + '/test' + Session.getToken();
    return instance.post(url,params);
  },

  showPaypal(data) {
    var url = ConfigAPI.baseURL + "payments/paypal/" + data.id + Session.getToken();
    return instance.get(url);
  },
  savePaypal(data) {
    var params = data
    var url = ConfigAPI.baseURL + "payments/paypal/" + data.id + Session.getToken();
    return instance.post(url,params);
  },
  testPaypal(data) {
    var params = data
    var url = ConfigAPI.baseURL + "payments/paypal/" + data.id + '/test' + Session.getToken();
    return instance.post(url,params);
  },  
}

export default servicesAPI;
